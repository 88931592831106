import MuiSwipeableDrawer from '@mui/material/SwipeableDrawer'
import { styled } from '@mui/system'
import BHButton from '../../molecules/button'

const sidebarWidth = `100vw`

const HamburgerButton = styled(BHButton)`
	padding: 5px;
`

const SwipeableDrawer = styled(MuiSwipeableDrawer)`
	> .MuiPaper-root {
		width: ${sidebarWidth};
		overflow-x: hidden;
		padding: 10px 8px 32px;
		will-change: auto;
		.NavMenu-root {
			min-height: max-content; /* HYDREPLAT-139 */
			width: 100%;
			padding-top: 64px;
			.NavSubMenu-root {
				width: 100%;
				padding-top: 48px;
			}
		}
	}
`

const ButtonClose = styled(BHButton)`
	right: 5px;
	top: 18px;
	position: relative;

	&.iconClose {
		padding: 0;

		svg {
			font-size: ${({ theme }) => theme.typography.pxToRem(24)};
		}
	}
`

const MiniHeaderStyled = styled('div')`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--color-grey80);
	background: var(--color-secondary);
	position: fixed;
	width: 100%;
	padding: 10px;
	top: 0;
	left: 0;
	z-index: 10;
	.logo {
		width: 158px;
	}
`

export { ButtonClose, HamburgerButton, SwipeableDrawer, MiniHeaderStyled }
